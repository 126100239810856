import DataUtil from "../../Utils/DataUtil";

export function getFirstTwoPaths(urlPath) {
  const segments = urlPath.split("/").filter((segment) => segment !== "");
  const firstThreeSegments = segments.slice(0, 2);
  const result = "/" + firstThreeSegments.join("/");
  return result;
}

export function getAirportClientFromPath() {
  return window.location.pathname.split("/").filter((x) => x)[0] || "";
}

export function constructDateWithTimezoneIdentifier(
  date = new Date(),
  timeZoneIdentifier,
  timeZoneUTC,
  forceConvert = false
) {
  if (timeZoneIdentifier) {
    const isTimezoneAdjusted = DataUtil.isTimezoneAdjusted(timeZoneUTC);
    if (isTimezoneAdjusted || forceConvert) {
      return new Date(
        new Date(date).toLocaleString("en-US", {
          timeZone: timeZoneIdentifier,
        })
      );
    } else {
      return new Date(new Date(date).toLocaleString());
    }
  } else {
    return new Date(new Date(date).toLocaleString());
  }
}
